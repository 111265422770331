export const roleOptions = [
  {id: 'ACCOUNT_OWNER', name: 'Account Owner'},
  {id: 'USER', name: 'Regular User'},
];

export const adminRoleOptions = [
  {id: 'ADMIN', name: 'ADMIN'},
  {id: 'EDITOR', name: 'EDITOR'},
];

export const getRoleById = id => {
  return [...roleOptions, ...adminRoleOptions].find(opt => opt.id === id);
};

export const inForce = {
  assent: 'On Royal Assent',
  'to be proclaimed': 'To be proclaimed',
  'on date': 'Effective as of',
  'retro on date': 'Deemed retroactive to',
};

export const emailFrequencyOptions = [
  {id: 'Never', name: 'Never'},
  {id: 'Daily', name: 'Daily Summary'},
  {id: 'Weekly', name: 'Weekly Summary'},
];

export const monthOptions = [
  {id: 1, name: 'January'},
  {id: 2, name: 'February'},
  {id: 3, name: 'March'},
  {id: 4, name: 'April'},
  {id: 5, name: 'May'},
  {id: 6, name: 'June'},
  {id: 7, name: 'July'},
  {id: 8, name: 'August'},
  {id: 9, name: 'September'},
  {id: 10, name: 'October'},
  {id: 11, name: 'November'},
  {id: 12, name: 'December'},
];

export const dayOptions = [
  {id: 1, name: '1st'},
  {id: 2, name: '2nd'},
  {id: 3, name: '3rd'},
  {id: 4, name: '4th'},
  {id: 5, name: '5th'},
  {id: 6, name: '6th'},
  {id: 7, name: '7th'},
  {id: 8, name: '8th'},
  {id: 9, name: '9th'},
  {id: 10, name: '10th'},
  {id: 11, name: '11th'},
  {id: 12, name: '12th'},
  {id: 13, name: '13th'},
  {id: 14, name: '14th'},
  {id: 15, name: '15th'},
  {id: 16, name: '16th'},
  {id: 17, name: '17th'},
  {id: 18, name: '18th'},
  {id: 19, name: '19th'},
  {id: 20, name: '20th'},
  {id: 21, name: '21st'},
  {id: 22, name: '22nd'},
  {id: 23, name: '23rd'},
  {id: 24, name: '24th'},
  {id: 25, name: '25th'},
  {id: 26, name: '26th'},
  {id: 27, name: '27th'},
  {id: 28, name: '28th'},
  {id: 29, name: '29th'},
  {id: 30, name: '30th'},
  {id: 31, name: '31st'},
];

export const sizeOptions = [
  'Small',
  'Medium',
  'Large',
  'Enterprise',
  'Service Provider',
].map(i => ({id: i, name: i}));

export const billStatuses = [
  'Introduction',
  'Second Reading',
  'Committee',
  'Amended',
  'Third Reading',
  'Royal Assent',
  'Filed',
].map(i => ({id: i, name: i}));

// Create a quick lookup map for sort order of statuses
export const billStatusSortOrder = {
  Introduction: 0,
  'Introduction (House)': 1,
  'Introduction (Senate)': 2,
  'Second Reading': 3,
  'Second Reading (House)': 4,
  'Second Reading (Senate)': 5,
  'Referral for Consultation': 6,
  'Passage in Principle': 7,
  Committee: 8,
  'Committee (House)': 9,
  'Committee (Senate)': 10,
  Amended: 11,
  'Amended (House)': 12,
  'Amended (Senate)': 13,
  'Report Stage': 14,
  'Third Reading': 15,
  'Third Reading (House)': 16,
  'Third Reading (Senate)': 17,
  Passage: 18,
  'Royal Assent': 19,
  Assent: 20,
  Filed: 21,
};

export const billStatusDescriptions = {
  Introduction:
    'This is also called First Reading. The government introduces (or presents) the text of a new Bill in the legislature. Sometimes private members (e.g., backbenchers or members of the Opposition) introduce Bills - they rarely make it past First Reading.',
  'Second Reading':
    'During this stage, the government and the opposition parties debate and vote on the Bill.',
  Committee:
    'Bills are referred to specialized committees for review. Committees include members of the government and opposition parties. The Committee may hold hearings and hear submissions from the public. They may also propose amendments to the Bill.',
  Amended: 'Amendments to the Bill may be proposed.',
  'Third Reading':
    'The government and the opposition parties may debate the Bill further. Once a Bill is voted on and passed at Third Reading, it is generally considered final.',
  'Royal Assent':
    'The Lieutenant Governor (or Governor General for federal Bills) signs the bill and it is “on the books” as a new Act.',
  // Filed: 'The bill is filed.',
  'In Force': 'The amendment is in force.',
};

export const federalBillStatuses = [
  'Introduction (House)',
  'Second Reading (House)',
  'Committee (House)',
  'Amended (House)',
  'Third Reading (House)',
  'Introduction (Senate)',
  'Second Reading (Senate)',
  'Committee (Senate)',
  'Amended (Senate)',
  'Third Reading (Senate)',
  'Filed',
  'Royal Assent',
].map(i => ({id: i, name: i}));

export const federalBillStatusDescriptions = {
  'Introduction (House)':
    'The government introduces (or presents) the text of a new Bill in the legislature. Sometimes private members (e.g., backbenchers or members of the Opposition) introduce Bills - they rarely make it past First Reading.',
  'Second Reading (House)':
    'During this stage, the government and the opposition parties debate and vote on the Bill.',
  'Committee (House)':
    'Bills are referred to specialized committees for review. Committees include members of the government and opposition parties. The Committee may hold hearings and hear submissions from the public. They may also propose amendments to the Bill.',
  'Amended (House)': 'Amendments to the Bill may be proposed.',
  'Third Reading (House)':
    'The government and the opposition parties may debate the Bill further. After a Bill is voted on and passed at Third Reading, it is typically referred to the Senate.',
  'Introduction (Senate)':
    'This stage, where the Senate begins its review, is only for federal Bills.',
  'Second Reading (Senate)':
    'This stage is only for federal Bills. During this stage, the Senate members debate and vote on the Bill.',
  'Committee (Senate)':
    'This stage is only for federal Bills. Bills are referred to specialized Senate committees for review.  The Committee may hold hearings and hear submissions from the public. They may also propose amendments to the Bill.',
  'Amended (Senate)': 'Amendments to the Bill may be proposed.',
  'Third Reading (Senate)':
    'This stage is only for federal Bills. The Senate members may debate the Bill further. Once a Bill is voted on and passed at Third Reading, it is generally considered final.',
  'Royal Assent':
    'The Lieutenant Governor (or Governor General for federal Bills) signs the bill and it is “on the books” as a new Act.',
  // Filed: 'The bill is filed.',
  'In Force': 'The amendment is in force.',
};

export const quebecBillStatuses = [
  'Introduction',
  'Referral for Consultation',
  'Passage in Principle',
  'Committee',
  'Report Stage',
  'Passage',
  'Assent',
  'Filed',
].map(i => ({id: i, name: i}));

export const quebecBillStatusDescriptions = {
  Introduction:
    'The government introduces (or presents) the text of a new Bill in the Assembly. Sometimes private members (e.g., backbenchers or members of the Opposition) introduce Bills - they rarely make it past Introduction.',
  'Referral for Consultation':
    'This optional stage allows MNAs to learn the views of persons or bodies affected by a bill. To this end, the Government House Leader moves that the bill be referred to a committee for consultation.',
  'Passage in Principle':
    'During this stage, MNAs debate and vote on the Bill.',
  Committee:
    'Bills are referred to specialized committees for review. Committees include members of the government and Opposition parties. The Committee may hold hearings and hear submissions from the public. They may also propose amendments to the Bill.',
  'Report Stage':
    "The Assembly votes on the committee's report, which must be adopted for the process to continue.",
  Passage: 'This is the final stage before a bill is given assent.',
  Assent: 'The Bill is passed.',
  'In Force': 'The amendment is in force.',
};

export const countryOptions = [
  {id: 'CA', name: 'Canada'},
  {id: '-', name: '-----'},
  {id: 'AF', name: 'Afghanistan'},
  {id: 'AX', name: 'Åland Islands'},
  {id: 'AL', name: 'Albania'},
  {id: 'DZ', name: 'Algeria'},
  {id: 'AD', name: 'Andorra'},
  {id: 'AO', name: 'Angola'},
  {id: 'AI', name: 'Anguilla'},
  {id: 'AQ', name: 'Antarctica'},
  {id: 'AG', name: 'Antigua &amp; Barbuda'},
  {id: 'AR', name: 'Argentina'},
  {id: 'AM', name: 'Armenia'},
  {id: 'AW', name: 'Aruba'},
  {id: 'AC', name: 'Ascension Island'},
  {id: 'AU', name: 'Australia'},
  {id: 'AT', name: 'Austria'},
  {id: 'AZ', name: 'Azerbaijan'},
  {id: 'BS', name: 'Bahamas'},
  {id: 'BH', name: 'Bahrain'},
  {id: 'BD', name: 'Bangladesh'},
  {id: 'BB', name: 'Barbados'},
  {id: 'BY', name: 'Belarus'},
  {id: 'BE', name: 'Belgium'},
  {id: 'BZ', name: 'Belize'},
  {id: 'BJ', name: 'Benin'},
  {id: 'BM', name: 'Bermuda'},
  {id: 'BT', name: 'Bhutan'},
  {id: 'BO', name: 'Bolivia'},
  {id: 'BA', name: 'Bosnia &amp; Herzegovina'},
  {id: 'BW', name: 'Botswana'},
  {id: 'BV', name: 'Bouvet Island'},
  {id: 'BR', name: 'Brazil'},
  {id: 'IO', name: 'British Indian Ocean Territory'},
  {id: 'VG', name: 'British Virgin Islands'},
  {id: 'BN', name: 'Brunei'},
  {id: 'BG', name: 'Bulgaria'},
  {id: 'BF', name: 'Burkina Faso'},
  {id: 'BI', name: 'Burundi'},
  {id: 'KH', name: 'Cambodia'},
  {id: 'CM', name: 'Cameroon'},

  {id: 'CV', name: 'Cape Verde'},
  {id: 'BQ', name: 'Caribbean Netherlands'},
  {id: 'KY', name: 'Cayman Islands'},
  {id: 'CF', name: 'Central African Republic'},
  {id: 'TD', name: 'Chad'},
  {id: 'CL', name: 'Chile'},
  {id: 'CN', name: 'China'},
  {id: 'CO', name: 'Colombia'},
  {id: 'KM', name: 'Comoros'},
  {id: 'CG', name: 'Congo - Brazzaville'},
  {id: 'CD', name: 'Congo - Kinshasa'},
  {id: 'CK', name: 'Cook Islands'},
  {id: 'CR', name: 'Costa Rica'},
  {id: 'CI', name: 'Côte d’Ivoire'},
  {id: 'HR', name: 'Croatia'},
  {id: 'CW', name: 'Curaçao'},
  {id: 'CY', name: 'Cyprus'},
  {id: 'CZ', name: 'Czechia'},
  {id: 'DK', name: 'Denmark'},
  {id: 'DJ', name: 'Djibouti'},
  {id: 'DM', name: 'Dominica'},
  {id: 'DO', name: 'Dominican Republic'},
  {id: 'EC', name: 'Ecuador'},
  {id: 'EG', name: 'Egypt'},
  {id: 'SV', name: 'El Salvador'},
  {id: 'GQ', name: 'Equatorial Guinea'},
  {id: 'ER', name: 'Eritrea'},
  {id: 'EE', name: 'Estonia'},
  {id: 'SZ', name: 'Eswatini'},
  {id: 'ET', name: 'Ethiopia'},
  {id: 'FK', name: 'Falkland Islands'},
  {id: 'FO', name: 'Faroe Islands'},
  {id: 'FJ', name: 'Fiji'},
  {id: 'FI', name: 'Finland'},
  {id: 'FR', name: 'France'},
  {id: 'GF', name: 'French Guiana'},
  {id: 'PF', name: 'French Polynesia'},
  {id: 'TF', name: 'French Southern Territories'},
  {id: 'GA', name: 'Gabon'},
  {id: 'GM', name: 'Gambia'},
  {id: 'GE', name: 'Georgia'},
  {id: 'DE', name: 'Germany'},
  {id: 'GH', name: 'Ghana'},
  {id: 'GI', name: 'Gibraltar'},
  {id: 'GR', name: 'Greece'},
  {id: 'GL', name: 'Greenland'},
  {id: 'GD', name: 'Grenada'},
  {id: 'GP', name: 'Guadeloupe'},
  {id: 'GU', name: 'Guam'},
  {id: 'GT', name: 'Guatemala'},
  {id: 'GG', name: 'Guernsey'},
  {id: 'GN', name: 'Guinea'},
  {id: 'GW', name: 'Guinea-Bissau'},
  {id: 'GY', name: 'Guyana'},
  {id: 'HT', name: 'Haiti'},
  {id: 'HN', name: 'Honduras'},
  {id: 'HK', name: 'Hong Kong SAR China'},
  {id: 'HU', name: 'Hungary'},
  {id: 'IS', name: 'Iceland'},
  {id: 'IN', name: 'India'},
  {id: 'ID', name: 'Indonesia'},
  {id: 'IQ', name: 'Iraq'},
  {id: 'IE', name: 'Ireland'},
  {id: 'IM', name: 'Isle of Man'},
  {id: 'IL', name: 'Israel'},
  {id: 'IT', name: 'Italy'},
  {id: 'JM', name: 'Jamaica'},
  {id: 'JP', name: 'Japan'},
  {id: 'JE', name: 'Jersey'},
  {id: 'JO', name: 'Jordan'},
  {id: 'KZ', name: 'Kazakhstan'},
  {id: 'KE', name: 'Kenya'},
  {id: 'KI', name: 'Kiribati'},
  {id: 'XK', name: 'Kosovo'},
  {id: 'KW', name: 'Kuwait'},
  {id: 'KG', name: 'Kyrgyzstan'},
  {id: 'LA', name: 'Laos'},
  {id: 'LV', name: 'Latvia'},
  {id: 'LB', name: 'Lebanon'},
  {id: 'LS', name: 'Lesotho'},
  {id: 'LR', name: 'Liberia'},
  {id: 'LY', name: 'Libya'},
  {id: 'LI', name: 'Liechtenstein'},
  {id: 'LT', name: 'Lithuania'},
  {id: 'LU', name: 'Luxembourg'},
  {id: 'MO', name: 'Macao SAR China'},
  {id: 'MG', name: 'Madagascar'},
  {id: 'MW', name: 'Malawi'},
  {id: 'MY', name: 'Malaysia'},
  {id: 'MV', name: 'Maldives'},
  {id: 'ML', name: 'Mali'},
  {id: 'MT', name: 'Malta'},
  {id: 'MQ', name: 'Martinique'},
  {id: 'MR', name: 'Mauritania'},
  {id: 'MU', name: 'Mauritius'},
  {id: 'YT', name: 'Mayotte'},
  {id: 'MX', name: 'Mexico'},
  {id: 'MD', name: 'Moldova'},
  {id: 'MC', name: 'Monaco'},
  {id: 'MN', name: 'Mongolia'},
  {id: 'ME', name: 'Montenegro'},
  {id: 'MS', name: 'Montserrat'},
  {id: 'MA', name: 'Morocco'},
  {id: 'MZ', name: 'Mozambique'},
  {id: 'MM', name: 'Myanmar (Burma)'},
  {id: 'NA', name: 'Namibia'},
  {id: 'NR', name: 'Nauru'},
  {id: 'NP', name: 'Nepal'},
  {id: 'NL', name: 'Netherlands'},
  {id: 'NC', name: 'New Caledonia'},
  {id: 'NZ', name: 'New Zealand'},
  {id: 'NI', name: 'Nicaragua'},
  {id: 'NE', name: 'Niger'},
  {id: 'NG', name: 'Nigeria'},
  {id: 'NU', name: 'Niue'},
  {id: 'MK', name: 'North Macedonia'},
  {id: 'NO', name: 'Norway'},
  {id: 'OM', name: 'Oman'},
  {id: 'PK', name: 'Pakistan'},
  {id: 'PS', name: 'Palestinian Territories'},
  {id: 'PA', name: 'Panama'},
  {id: 'PG', name: 'Papua New Guinea'},
  {id: 'PY', name: 'Paraguay'},
  {id: 'PE', name: 'Peru'},
  {id: 'PH', name: 'Philippines'},
  {id: 'PN', name: 'Pitcairn Islands'},
  {id: 'PL', name: 'Poland'},
  {id: 'PT', name: 'Portugal'},
  {id: 'PR', name: 'Puerto Rico'},
  {id: 'QA', name: 'Qatar'},
  {id: 'RE', name: 'Réunion'},
  {id: 'RO', name: 'Romania'},
  {id: 'RU', name: 'Russia'},
  {id: 'RW', name: 'Rwanda'},
  {id: 'WS', name: 'Samoa'},
  {id: 'SM', name: 'San Marino'},
  {id: 'ST', name: 'São Tomé &amp; Príncipe'},
  {id: 'SA', name: 'Saudi Arabia'},
  {id: 'SN', name: 'Senegal'},
  {id: 'RS', name: 'Serbia'},
  {id: 'SC', name: 'Seychelles'},
  {id: 'SL', name: 'Sierra Leone'},
  {id: 'SG', name: 'Singapore'},
  {id: 'SX', name: 'Sint Maarten'},
  {id: 'SK', name: 'Slovakia'},
  {id: 'SI', name: 'Slovenia'},
  {id: 'SB', name: 'Solomon Islands'},
  {id: 'SO', name: 'Somalia'},
  {id: 'ZA', name: 'South Africa'},
  {id: 'GS', name: 'South Georgia &amp; South Sandwich Islands'},
  {id: 'KR', name: 'South Korea'},
  {id: 'SS', name: 'South Sudan'},
  {id: 'ES', name: 'Spain'},
  {id: 'LK', name: 'Sri Lanka'},
  {id: 'BL', name: 'St. Barthélemy'},
  {id: 'SH', name: 'St. Helena'},
  {id: 'KN', name: 'St. Kitts &amp; Nevis'},
  {id: 'LC', name: 'St. Lucia'},
  {id: 'MF', name: 'St. Martin'},
  {id: 'PM', name: 'St. Pierre &amp; Miquelon'},
  {id: 'VC', name: 'St. Vincent &amp; Grenadines'},
  {id: 'SR', name: 'Suriname'},
  {id: 'SJ', name: 'Svalbard &amp; Jan Mayen'},
  {id: 'SE', name: 'Sweden'},
  {id: 'CH', name: 'Switzerland'},
  {id: 'TW', name: 'Taiwan'},
  {id: 'TJ', name: 'Tajikistan'},
  {id: 'TZ', name: 'Tanzania'},
  {id: 'TH', name: 'Thailand'},
  {id: 'TL', name: 'Timor-Leste'},
  {id: 'TG', name: 'Togo'},
  {id: 'TK', name: 'Tokelau'},
  {id: 'TO', name: 'Tonga'},
  {id: 'TT', name: 'Trinidad &amp; Tobago'},
  {id: 'TA', name: 'Tristan da Cunha'},
  {id: 'TN', name: 'Tunisia'},
  {id: 'TR', name: 'Turkey'},
  {id: 'TM', name: 'Turkmenistan'},
  {id: 'TC', name: 'Turks &amp; Caicos Islands'},
  {id: 'TV', name: 'Tuvalu'},
  {id: 'UG', name: 'Uganda'},
  {id: 'UA', name: 'Ukraine'},
  {id: 'AE', name: 'United Arab Emirates'},
  {id: 'GB', name: 'United Kingdom'},
  {id: 'US', name: 'United States'},
  {id: 'UY', name: 'Uruguay'},
  {id: 'UZ', name: 'Uzbekistan'},
  {id: 'VU', name: 'Vanuatu'},
  {id: 'VA', name: 'Vatican City'},
  {id: 'VE', name: 'Venezuela'},
  {id: 'VN', name: 'Vietnam'},
  {id: 'WF', name: 'Wallis &amp; Futuna'},
  {id: 'EH', name: 'Western Sahara'},
  {id: 'YE', name: 'Yemen'},
  {id: 'ZM', name: 'Zambia'},
  {id: 'ZW', name: 'Zimbabwe'},
];

export const provinceOptions = [
  {id: 'AB', name: 'Alberta'},
  {id: 'BC', name: 'British Columbia'},
  {id: 'MB', name: 'Manitoba'},
  {id: 'NB', name: 'New Brunswick'},
  {id: 'NL', name: 'Newfoundland and Labrador'},
  {id: 'NS', name: 'Nova Scotia'},
  {id: 'NT', name: 'Northwest Territories'},
  {id: 'NU', name: 'Nunavut'},
  {id: 'ON', name: 'Ontario'},
  {id: 'PE', name: 'Prince Edward Island'},
  {id: 'QC', name: 'Québec'},
  {id: 'SK', name: 'Saskatchewan'},
  {id: 'YT', name: 'Yukon'},
];

export const ChecklistRequirementStatuses = {
  'Not Started': 'Not Started',
  'In Progress': 'In Progress',
  Done: 'Done',
};

export const ChecklistsRequirementTypes = {
  'Employee Committees': 'Employee Committees',
  'Employee Communications': 'Employee Communications',
  Hiring: 'Hiring',
  'Ongoing Compliance': 'Ongoing Compliance',
  'Policies & Procedures': 'Policies & Procedures',
  Records: 'Records',
  Reporting: 'Reporting',
  Training: 'Training',
};
